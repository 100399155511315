export enum InformationQuestionOptionSource {
  CustomOptions = 1,
  Unions = 2,
}

export enum InformationQuestionType {
  ChoiceSelectOne = 1,
  ChoiceSelectMany = 2,
  DropdownSelectMany = 3,
  Date = 4,
}

export enum CourseInformationQuestionGroup {
  Prerequisite = 1,
  Questionnaire = 2
}