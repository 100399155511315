










































import { Component, Prop } from "vue-property-decorator";
import CourseAppVue from "@/apps/CourseAppVue.vue";
import { CourseModel } from "@/core/models";
import { CourseService } from "@/core/services";
import { DeliveryType } from "@/core/constants";

@Component({name: "CourseNavigation"})
export default class CourseNavigation extends CourseAppVue {
  @Prop({default: null}) course: CourseModel;
  isAssessment = false;

  async created() {
    let innerCourse = this.course;

    if (!innerCourse || !innerCourse.deliveryTypeId) {
      innerCourse = await this.GetCourse();
    }

    this.isAssessment = !!innerCourse 
      && innerCourse.deliveryTypeId === +DeliveryType.FaceToFaceAssessment;
  }

  getCourseDetailsRoute() {
    return this.getRoute("courseDetails");
  }

  getCourseTranscriptsRoute() {
    return this.getRoute("courseTranscripts");
  }

  getCourseExamQuestionsRoute() {
    return this.getRoute("courseExamQuestionPages");
  }

  getCourseEmailTemplatesRoute() {
    return {
      name: "courseEmailTemplateList",
      params: {
        id: this.$route.params.id,
        locale: "en",
      },
    };
  }

  getCourseQuestionnairesRoute() {
    return {
      name: "courseQuestionnaire",
      params: {
        id: this.$route.params.id,
      },
    };
  }

  getCourseLocalisationRoute() {
    return this.getRoute("courseLocalisation");
  }

  getCourseCertificatesRoute() {
    return this.getRoute("courseCertificates");
  }

  getCourseCertificateTemplatesRoute() {
    return this.getRoute("courseCertificateTemplates");
  }

  getCourseMaterialsRoute() {
    return this.getRoute("courseMaterials");
  }

  getCourseInformationQuestionsRoute() {
    return this.getRoute("courseInformationQuestions");
  }

  getCourseAutomaticCertificateAwardRoute() {
    return this.getRoute("courseAutomaticCertificateAward");
  }
  
  getActiveindex() {
    return this.$route.name;
  }

  getRoute(name: string) {
    return {
      name,
      params: {
        id: this.$route.params.id,
      },
    };
  }

  private async GetCourse() {
    return await CourseService.getById(this.$route.params.id);
  }
}
