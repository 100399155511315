import { 
  HttpService,
  DownloadProvider } from "./common";
import {
  CourseInformationQuestionModel,
  CourseInformationQuestionLookupsModel,
  FileDownloadModel,
  InformationQuestionLookupsModel
} from "@/core/models";
import { UploadService } from ".";

export const CourseInformationQuestionService = {

  getByCourseId(
    courseId: number, 
    groupId: number, 
    locale: string) {

    const url = `/course-information-questions/${courseId}/group/${groupId}?locale=${locale}`;

    return HttpService.getData<CourseInformationQuestionModel[]>(url);
  },

  getQuestionById(
    courseInformationQuestionId: any,
    locale: string,
    alternativeLocale: string) {

    return HttpService.getData<CourseInformationQuestionModel>(
      `/course-information-questions/${courseInformationQuestionId}` +
      `?locale=${locale}&altLocale=${alternativeLocale}`);
  },

  add(model: CourseInformationQuestionModel) {
    return HttpService.postModel(`/course-information-questions/`, model);
  },

  update(model: CourseInformationQuestionModel) {
    return HttpService.putModel(`/course-information-questions/`, model);
  },

  delete(courseInformationQuestionId: number) {
    return HttpService.delete(`/course-information-questions/${courseInformationQuestionId}`);
  },

  getCourseInformationQuestionsLookups() {
    const url = `/course-information-questions/lookups`;

    return HttpService.getData<CourseInformationQuestionLookupsModel>(url);
  },

  getInformationQuestionsLookups() {
    const url = `/course-information-questions/question-lookups`;

    return HttpService.getData<InformationQuestionLookupsModel>(url);
  },   
  
  async exportInformationQuestionsXlf(
    courseId: number, 
    courseInformationQuestionId: number, 
    sourceLocale: string, 
    targetLocale: string) {
    
      const url = "/course-information-questions/export-information-questions-xlf"
      + `/${courseId}/${courseInformationQuestionId}/${sourceLocale}/${targetLocale}`;
    const response = await HttpService.getData<FileDownloadModel>(url);

    DownloadProvider.downloadResponse(response);
  },

  async uploadInformationQuestionsXlf(
    courseId: number,
    courseInformationQuestionId: number, 
    targetLocale: string, 
    file: any) {
    
      const url = "/course-information-questions/import-information-questions-xlf"
      + `/${courseId}/${courseInformationQuestionId}/${targetLocale}`;

    return await UploadService.uploadAsAny(url, file) as boolean;
  },

}