import {
  SimpleLookupModel,
  Validatable } from "@/core/models";

export class AutomaticCertificateAwardModel extends Validatable {
    id?: number = undefined;
    courseId: number = 0;
    licenseStrandId?: number = undefined;
    levelIds: number[] = [];
}

export class AutomaticCertificateAwardCollectionModel extends AutomaticCertificateAwardModel {
  licenseStrandName: string;
  levels: SimpleLookupModel[];
}
