import { 
  Validatable,
  SimpleLookupModel } from "@/core/models";
import { DefaultLocaleId } from "@/core/constants";

export class CourseInformationQuestionModel extends Validatable {
  id?: number = undefined;
  courseId: number = 0;
  sortOrder: number = 0;
  informationQuestionGroupId: number = 0;
  informationQuestion: InformationQuestionModel = new InformationQuestionModel();
}

export class InformationQuestionModel {
  id: number = 0;
  locale: number = DefaultLocaleId;
  questionIdentifier: string = "";
  question: string = "";
  informationQuestionTypeId: number = 1;
  informationQuestionTypeName: string = "";
  informationQuestionOptionSourceId: number = 1;
  options: InformationQuestionOptionModel[] = [];
  alternativeLanguageQuestion?: InformationQuestionModel = undefined;
}

export class InformationQuestionOptionModel {
  informationQuestionOptionId?: number = undefined;
  optionIdentifier = "";
  content: string = "";
}

export class CourseInformationQuestionLookupsModel {
  courseInformationQuestionGroups: SimpleLookupModel[] = [];
}

export class InformationQuestionLookupsModel {
  informationQuestionTypes: SimpleLookupModel[] = [];
  informationQuestionOptionSources: SimpleLookupModel[] = [];
}